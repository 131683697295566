import { CardElement } from '@stripe/react-stripe-js'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`

export const Title = styled.div`
  font-size: 23px;
  font-weight: bold;
  font-family: 'Rammetto One';
  padding-top: 20px;
  margin-bottom: 40px;
  color: #fff;
`

export const Plan = styled.div`
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.2);
  min-width: 380px;
  > :first-child {
    font-size: 19px;
    font-weight: bold;
    font-family: 'Rammetto One';
    margin-bottom: 12px;
  }
  > :nth-child(2) {
    font-size: 19px;
  }
`

export const Price = styled.div`
  font-weight: bold;
  margin-top: 10px;
`

export const Form = styled.form`
  margin-top: 24px;
  min-width: 480px;
  > * { margin-bottom: 10px; }
  > :last-child { margin-bottom: 0; }
  button {
    float: right;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
`

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  > :first-child {
    font-size: 23px;
    color: ${props => props.theme.primary};
    font-family: 'Rammetto One';
    margin-bottom: 40px;
  }
`

export const CardSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledCardElement = styled(CardElement)`
  width: 100%;
  background-color: #ebebeb;
  padding: 10px;
  border-radius: 5px;
`
