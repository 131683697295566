import { useLocation } from '@reach/router'
import React from "react"
import LegacyStrapiUpdate from '../components/pages/LegacyUpdateStripe'
import UpdateSubscription from '../components/pages/UpdateSubscriptionPage2'
import Layout from "../layouts/layout"

const UpdateSubscriptionPage = props => {
  if (typeof window === "undefined") return null
  const location = useLocation()
  return (
    <Layout>
      { new URLSearchParams(location.search).get('token')
        ? <LegacyStrapiUpdate />
        : <UpdateSubscription {...props} />
      }
    </Layout>
  )
}

export default UpdateSubscriptionPage
