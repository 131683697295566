import { navigate } from 'gatsby'
import { useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import { NON_DECIMAL_CURRENCIES } from '../../../constants/currencies'
import { CurrentUserState } from '../../../state/CurrentUserState'
import useData from './useData'

export interface IPlanOption {
  id: string,
  interval: 'yearly' | 'monthly' | 'quarterly',
  currency: string,
  amount: number,
  isCurrent: boolean,
  upTo: number,
  tiers: {
    amount: number,
    upTo: number,
  }[]
}

export interface IFormattedPlanOption {
  id: string,
  title: string,
  isCurrent: boolean,
  selected: boolean,
  displayedPrice: string,
  onSelect?: () => void,
  priceInfo: string | null
}

const UpdateSubscriptionState = () => {
  const { loading, data, findPlan, updatePlan, currentPlan } = useData()
  const { currentUser } = CurrentUserState.useContainer()
  const [newInterval, setNewInterval] = useState(data?.subscription.interval)
  const [nbChildren, setNbChildren] = useState(currentUser?.children.length || 0)
  const [options, setOptions] = useState<any[]>([])
  const [selectedOption, selectOption] = useState<IPlanOption | null>(null)
  const [loadingPlans, setLoadingPlans] = useState(false)

  const fetchPlans = async () => {
    if (loadingPlans) return
    setLoadingPlans(true)
    const currentInterval = data?.subscription.interval
    const planOptions = await Promise.all(['yearly', 'monthly', 'quarterly'].map(async (interval: any) => {
      let plan
      if (currentPlan && interval === currentInterval) {
        plan = { tiers: [], ...currentPlan }
      } else {
        plan =  await findPlan(data?.subscription.currency || 'usd', interval)
      }
      let i = 0
      while (i < plan.tiers?.length - 1 && plan.tiers[i]?.upTo < (data?.maxIncludedChildren || 0)) {
        i += 1
      }

      const tier = (plan.tiers && plan.tiers[i]) || {}
      if (interval === currentInterval) {
        selectOption({
          ...plan,
          isCurrent: interval === currentInterval,
          interval,
          ...tier
        })
      }
      return {
        ...plan,
        isCurrent: interval === currentInterval,
        interval,
        ...tier
      }
    }))
    setOptions(planOptions)
    setLoadingPlans(false)
  }
  
  useEffect(() => {
    if (data?.subscription && options.length === 0) {
      fetchPlans()
    }
  }, [options, data])

  useEffect(() => {
    if (!loading && !newInterval) {
      setNbChildren(data?.maxIncludedChildren || 0)
      setNewInterval(data?.subscription.interval)
    }
  }, [loading])

  const canSubmit = useMemo(() => {
    if (!selectedOption) return false
    if (selectedOption.isCurrent) return false
    return true
  }, [selectedOption])

  const onSubmit = async () => {
    if (canSubmit && selectedOption) {
      await updatePlan(selectedOption.id, nbChildren)
      navigate('/account?forceRefetch=true')
    }
  }

  const getTitle = (option: IPlanOption) => {
    const titles = {
      'monthly': 'Monthly',
      'quarterly': 'Quarterly',
      'biyearly': '6 Months',
      'yearly': '12 Months'
    }
    return titles[option.interval]
  }

  const getPriceInfo = (option: IPlanOption) => {
    if (option.interval === 'monthly') return null
    if (!data?.subscription.currency) return ''

    let amount = option.amount
    if (option.interval === 'yearly') amount /= 12
    if (option.interval === 'quarterly') amount /= 3

    if (NON_DECIMAL_CURRENCIES.indexOf(data?.subscription.currency) < 0) {
      amount /= 100 
    }
    
    return `Equivalent to ${new Intl.NumberFormat('us-US',
    { style: 'currency', currency: data?.subscription.currency.toUpperCase()
  }).format(amount || 0)} per month`
  }

  const getPrice = (option: IPlanOption) => {
    if (!data?.subscription.currency) return ''
    let amount = option.amount

    let term = '/ Month'
    if (option.interval === 'quarterly') term = '/ 3 Months'
    if (option.interval === 'yearly') term = '/ Year'

    if (NON_DECIMAL_CURRENCIES.indexOf(data?.subscription.currency) < 0) {
      amount /= 100 
    }
    return `${new Intl.NumberFormat('us-US',
    { style: 'currency', currency: data?.subscription.currency.toUpperCase()
  }).format(amount || 0)} ${term}`
  }

  const formattedOptions = useMemo((): IFormattedPlanOption[] => {
    const indices = ['monthly', 'yearly', 'quarterly']
  
    return options.map(option => {
      return {
        id: option.id,
        index: indices.indexOf(option.interval),
        title: getTitle(option),
        selected: selectedOption?.id === option.id,
        displayedPrice: getPrice(option),
        isCurrent: option.isCurrent,
        onSelect: !(selectedOption?.id === option.id) ? () => selectOption(option) : undefined,
        priceInfo: getPriceInfo(option),
        ...option
      }
    }).sort((a, b) => a.index - b.index)
  }, [options, selectedOption])

  const currentPlanDescription = useMemo(() => {
    if (!data?.subscription) return ''
    const terms = { 'monthly': 'Monthly', 'quarterly': 'Quarterly', 'biyearly': '6 Months', 'yearly': 'Yearly' }
    const paymentTerm = { 'monthly': '/ month', 'quarterly': '/ 3 months', 'biyearly': '/ 6 months', 'yearly': '/ year' }
    // @ts-ignore
    const date = data?.subscription.renewalDate && new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format(data.subscription.renewalDate)
    return `${terms[data.subscription.interval || '']} at ${data?.subscription.renewalCost} ${paymentTerm[data.subscription.interval || '']}. Next payment ${date}.`
  }, [options])

  return {
    loading,
    data,
    options: formattedOptions,
    currentPlanDescription,
    canSubmit,
    onSubmit,
  }
}

const UpdateSubscriptionStateContainer = createContainer(UpdateSubscriptionState)

export const UpdateSubscriptionStateProvider = UpdateSubscriptionStateContainer.Provider
export const useUpdateSubscriptionState = UpdateSubscriptionStateContainer.useContainer
