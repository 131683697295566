const CURRENCIES = {
  'usd': '$',
  'gbp': '£',
  'cad': 'CA$',
  'nzd': 'NZ$'
}

const getInterval = (plan) => {
  if (plan.interval === 'year') return 'year'
  if (plan.interval === 'month') {
    if (plan.interval_count === 3) return 'trimester'
    return 'month'
  }
}

export const getPlanAmount = (plan) => {
  if (plan.currency === 'jpy') {
    return plan.amount
  }
  return `${CURRENCIES[plan.currency] || plan.currency}${plan.amount / 100} / ${getInterval(plan)}`
}

export default { getPlanAmount }