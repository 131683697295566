import Loader from 'components/UI/Loader'
import { Link, navigate } from 'gatsby'
import { ArrowLeft, Button } from 'nzk-react-components'
import React from 'react'
import styled, { css } from 'styled-components'
import { UpdateSubscriptionStateProvider, useUpdateSubscriptionState } from './index.state'

const Wrapper = styled.div`
  color: #fff;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 65px;
  a {
    color: inherit;
  }
`

const BackButton = styled.div`
  position: absolute;
  top: 100px;
  left: 30px;
`

const LoaderWrapper = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Options = styled.div`
  max-width: 1028px;
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 14px;
  margin-bottom: 30px;
  @media screen and (max-width: 1028px) {
  }
`

const Option = styled.div`
  background-color: #fff;
  color: #000;
  border-radius: 12px;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 0 #AFAFAF, 0 12px 0 rgba(0,0,0,0.4);
  .option--title, .option--price {
    font-size: 28px;
  }
  .option--title, .option--price-info {
    color: #5D5D5D;
  }
  .option--title {
    margin-bottom: 20px;
  }
  .option--is-current {
    margin-bottom: 12px;
  }
  .option--price-info {
    font-size: 13px;
  }
  .option--price {
    margin-bottom: 12px;
  }
  .option--select {
    margin-top: 20px;
  }
  ${(props: { selected: boolean }) => props.selected && css`
    background-color: #CE60FC;
    box-shadow: 0 5px 0 #8E38B4, 0 12px 0 rgba(0,0,0,0.4);
    color: #fff;
    .option--title, .option--price-info {
      color: #fff;
    }
  `}
`

const Section = styled.div`
  text-align: center;
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    font-size: 22px;
    font-family: 'Rammetto One';
    margin-bottom: 16px;
  }
  a {
    margin-top: 30px;
  }
`

const UpdateSubscription = () => {
  const { loading, options, onSubmit, canSubmit, currentPlanDescription } = useUpdateSubscriptionState()

  if (loading) return <LoaderWrapper><Loader color='#fff' /></LoaderWrapper>
  return <Wrapper>
    <BackButton>
      <Button theme='primary' size='regular' round onClick={() => {
        navigate('/account')
      }}>
        <ArrowLeft width='100%' height='100%' />
      </Button>
    </BackButton>
    <Content>
      <Section>
        <div>Your Current Plan</div>
        <div>
          <p>{currentPlanDescription}</p>
          <p>Any changes to your plan will take effect from your next payment date.</p>
        </div>
      </Section>
      <Section>
        <div>Plan Options</div>
        <Options>
          { options.map(o => <Option key={o.id} selected={o.selected} onClick={() => o.onSelect && o.onSelect()}>
            <div className='option--title'>{o.title}</div>
            { o.isCurrent && <div className='option--is-current'>Current Plan</div> }
            <div className='option--price'>{o.displayedPrice}</div>
            <div className='option--price-info'>{o.priceInfo}</div>
            { o.onSelect && <div className='option--select'>
              <Button size='small' theme='primary' onClick={o.onSelect}>Select</Button>
            </div>
            }
          </Option>)}
        </Options>
        <Button size='regular' theme='confirm' onClick={onSubmit} disabled={!canSubmit}>Confirm</Button>
        <Link to='/account'>Keep current plan</Link>
      </Section>
    </Content>
  </Wrapper>
}

UpdateSubscription.propTypes = {

}

UpdateSubscription.defaultProps = {

}

export default () => {
  return <UpdateSubscriptionStateProvider>
    <UpdateSubscription />
  </UpdateSubscriptionStateProvider>
}
