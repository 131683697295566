import Button from "components/UI/Button"
import { navigate } from "gatsby"
import React from "react"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from '../../../config'


import Loader from "../../UI/Loader"
import UpdateSubscriptionPageState from './index.state'
import * as s from "./index.styles"
import { getPlanAmount } from './utils'

const stripePromise = loadStripe(config.stripeKey)

const UpdateSubscriptionPage = () => {
  const { plan, handleSubmit, success, submitting, element } = UpdateSubscriptionPageState.useContainer()

  if (success) return <s.Wrapper>
    <s.Success>
      <div>Success</div>
      <div><Button theme='primary' size='small' onClick={() => navigate('/')}>
        Dashboard
      </Button></div>
    </s.Success>
  </s.Wrapper>

  return (
    <s.Wrapper>
      { element }
      <s.Title>Update Subscription</s.Title>
      { plan && <s.Plan>
        <div>Plan</div>
        <div>{plan.nickname}</div>
        <s.Price>{getPlanAmount(plan)}</s.Price>
      </s.Plan> }
      <s.Form>
        <s.CardSectionWrapper>
          <s.StyledCardElement style={{base: {fontSize: '18px'}}} />
        </s.CardSectionWrapper>
        {
          submitting
            ? <s.LoaderWrapper><Loader /></s.LoaderWrapper>
            : <Button theme='primary' size='small' onClick={(e) => handleSubmit(e)}>Submit</Button>
        }
      </s.Form>
    </s.Wrapper>
  )
}

UpdateSubscriptionPage.propTypes = {}

UpdateSubscriptionPage.defaultProps = {}

const UpdateSubscriptionPageWithState = (props: any) => {
  const urlParams = new URLSearchParams(window.location.search)
  return <UpdateSubscriptionPageState.Provider initialState={{
    token: urlParams.get("token"),
  }}>
    <UpdateSubscriptionPage {...props} />
  </UpdateSubscriptionPageState.Provider>
}


const UpdateSubscriptionPageWithElements = (props) => <Elements stripe={stripePromise}>
  <UpdateSubscriptionPageWithState {...props} />
</Elements>

export default UpdateSubscriptionPageWithElements