import { useCallback, useMemo } from 'react'
import { NON_DECIMAL_CURRENCIES } from '../constants/currencies'
import { CurrentUserState } from '../state/CurrentUserState'

interface ISubscription {
  id: string
  status: string
  current_period_end: number
  cancel_at: number
  cancel_at_period_end: boolean
  trial_end: number
  current_period_start: number
  start_date: number
  quantity: number
  customer: {
    id: string
    card: {
      exp_year: string
      exp_month: string
      last4: string
      brand: string
    }
    balance: string
    invoices: {
      id: string
      amount_due: string
      created: string
      currency: string
      hosted_invoice_url: string
      invoice_pdf: string
      paid: string
      status: string
      period_start: string
      period_end: string
    }
    discount: {
      coupon: {
        percent_off: number
        valid: boolean
      }
    }
  }
  discount: {
    coupon: {
      percent_off: number
      valid: boolean
    }
  }
  plan: {
    id: string
    nickname: string
    amount: number
    currency: string
    interval: 'year' | 'month'
    interval_count: 1 | 3 | 6
    tiers?: {
      upTo: number
      amount: number
      unitAmount?: number
      flatAmount?: number
    }[]
  }
}

interface IHookReturn {
  tiered: boolean
  maxNbChildren: number
  maxIncludedChildren: number
  getAmountWithSubscriptionDiscount: (amount: number) => number
  canAddChildren: boolean
  isUnitPriced: boolean
  subscription: {
    type: string
    expired: boolean
    expiresAt: Date | undefined
    discountAmount: number
    status: string
    stripeBalance: string | null
    interval: 'yearly' | 'monthly' | 'quarterly' | null
    renewalDate: Date | null
    noDiscountPrice: string | null
    autoRenewCancelled: boolean | null
    renewalCost: string | null
    lastPaymentDate: Date | null
    discounted: boolean
    currency: string
    planId: string
  }
}

const useStripeSusbcription = (
  subscription: ISubscription
): IHookReturn | null => {
  const { currentUser } = CurrentUserState.useContainer()

  const tiered = useMemo(() => {
    return Boolean(subscription?.plan?.tiers && true)
  }, [subscription])

  const tiers = useMemo(() => {
    if (!subscription || !subscription.plan?.tiers) return null
    const newTiers = [...subscription.plan.tiers]
    const maxChildren = newTiers.reduce((max, d) => {
      if (d.upTo > max) return d.upTo
      return max
    }, 0)
    return newTiers.map((tier) => {
      let upTo = tier.upTo
      if (tier.upTo === null) upTo = maxChildren + 1
      return {
        ...tier,
        upTo,
      }
    })
  }, [subscription])

  const discountAmount = useMemo(() => {
    const subDiscount = subscription?.discount?.coupon
      ? (subscription?.discount?.coupon?.percent_off || 0) / 100
      : 0
    const subCustomer = subscription?.customer?.discount?.coupon
      ? (subscription?.customer?.discount?.coupon?.percent_off || 0) / 100
      : 0
    return Math.round((1 - (1 - subDiscount) * (1 - subCustomer)) * 10000) / 100
  }, [subscription])

  const applyDiscount = useCallback(
    (amount) => {
      const subDiscount =
        (subscription?.discount?.coupon?.percent_off || 0) / 100
      const subCustomer =
        (subscription?.customer?.discount?.coupon?.percent_off || 0) / 100
      if (subDiscount > 0) {
        return Math.round((1 - subDiscount) * amount * 100) / 100
      }
      return Math.round((1 - subCustomer) * amount * 100) / 100
    },
    [subscription]
  )

  const nbChildren = useMemo(() => {
    return currentUser?.children?.length || 0
  }, [currentUser])

  const currentTier = useMemo(() => {
    if (!tiers) return null
    if (!subscription) return null
    const quantity =
      subscription.quantity || (currentUser?.children || []).length
    if (!quantity) return null

    let i = 0
    while (i < tiers.length && tiers[i].upTo < quantity) {
      i += 1
    }
    return tiers[i]
  }, [subscription, tiers])

  const maxNbChildren = useMemo(() => {
    if (tiered) {
      const maxUpTo = Math.max(
        ...(subscription?.plan?.tiers || []).map((t) => t.upTo)
      )
      return (subscription?.plan?.tiers || [])
        .map((d) => d.amount && (d.upTo || maxUpTo + 1))
        .filter((d) => d)
        .reduce((max, value) => {
          if (value > max) return value
          return max
        }, 0)
    }
    if (currentUser?.customPlan) return currentUser?.children.length
    if (subscription?.plan?.nickname?.includes('Multiple')) return 3
    if (subscription?.plan?.nickname?.includes('Single')) return 1
    return 0
  }, [subscription, tiered])

  const maxIncludedChildren = useMemo(() => {
    if (!subscription) return 0
    if (currentTier) {
      return currentTier.upTo
    }
    if (subscription?.plan?.nickname?.includes('Multiple')) return 3
    if (subscription?.plan?.nickname?.includes('Single')) return 1
    return currentUser?.children.length || 0
  }, [subscription, currentTier])

  const baseType = useMemo(() => {
    if (!subscription) return ''
    if (subscription.plan?.interval === 'year') return 'Yearly plan'
    if (
      subscription.plan?.interval === 'month' &&
      subscription.plan?.interval_count === 3
    )
      return 'Quarterly plan'
    if (
      subscription.plan?.interval === 'month' &&
      subscription.plan?.interval_count === 1
    )
      return 'Monthly plan'
    if (
      subscription.plan?.interval === 'month' &&
      subscription.plan?.interval_count === 6
    )
      return '6 Month plan'
    return 'Plan'
  }, [subscription])

  const type = useMemo(() => {
    if (currentUser?.customPlan) return 'Custom plan'
    if (maxIncludedChildren > 1000) {
      return `${baseType} for ${nbChildren} children`
    }
    if (maxIncludedChildren > 1) {
      return `${baseType} for up to ${maxIncludedChildren} children`
    }
    return `${baseType} for 1 child`
  }, [maxIncludedChildren, nbChildren, baseType, currentUser])

  const status = useMemo(() => {
    if (subscription?.status === 'trialing') return 'Trialing'
    if (subscription?.status === 'active') return 'Active'
    if (subscription?.status === 'past_due') return 'Past due'
    if (subscription?.status === 'cancelled') return 'Cancelled'
    return 'Unknown'
  }, [subscription])

  const expiresAt = useMemo(() => {
    if (currentUser?.subscriptionEndDate)
      return new Date(currentUser.subscriptionEndDate)
    if (subscription && subscription.current_period_end)
      return new Date(subscription.current_period_end * 1000)
    return undefined
  }, [subscription, currentUser])

  const expired = useMemo(() => {
    return Date.now() > (expiresAt?.getTime() || 0)
  }, [expiresAt])

  const amount = useMemo(() => {
    if (!subscription) return null
    let currentAmount = subscription.plan?.amount
    if (currentTier) {
      if (currentTier.flatAmount || currentTier.unitAmount) {
        currentAmount =
          (currentTier.flatAmount || 0) +
          (currentTier.unitAmount || 0) * nbChildren
      } else {
        currentAmount = currentTier.amount
      }
    }
    if (NON_DECIMAL_CURRENCIES.indexOf(subscription.plan?.currency) < 0) {
      currentAmount /= 100
    }
    return currentAmount
  }, [subscription, currentTier, nbChildren])

  const stripeBalance = useMemo(() => {
    if (!subscription || !subscription.plan) return null
    let balance = -parseInt(subscription?.customer?.balance, 10) || 0
    if (NON_DECIMAL_CURRENCIES.indexOf(subscription?.plan?.currency) < 0) {
      balance /= 100
    }
    return new Intl.NumberFormat('us-US', {
      style: 'currency',
      currency: subscription.plan?.currency.toUpperCase(),
    }).format(balance || 0)
  }, [subscription])

  const noDiscountPrice = useMemo(() => {
    if (!subscription || !subscription.plan) return null
    return new Intl.NumberFormat('us-US', {
      style: 'currency',
      currency: subscription.plan?.currency.toUpperCase(),
    }).format(amount || 0)
  }, [amount])

  const renewalCost = useMemo(() => {
    if (!subscription || !subscription.plan || !amount) return null
    const discounted = applyDiscount(amount)
    return new Intl.NumberFormat('us-US', {
      style: 'currency',
      currency: subscription.plan?.currency.toUpperCase(),
    }).format(discounted)
  }, [subscription, amount])

  const renewalDate = useMemo(() => {
    if (!subscription) return null
    return new Date(subscription.current_period_end * 1000)
  }, [subscription])

  const autoRenewCancelled = useMemo(() => {
    if (!subscription) return null
    return subscription.cancel_at_period_end || false
  }, [subscription])

  const lastPaymentDate = useMemo(() => {
    if (!subscription) return null
    return new Date(subscription.current_period_start * 1000)
  }, [subscription])

  const interval = useMemo(() => {
    if (!subscription || !subscription.plan) return null
    if (subscription.plan.interval === 'year') return 'yearly'
    if (
      subscription.plan.interval === 'month' &&
      subscription.plan.interval_count === 3
    )
      return 'quarterly'
    return 'monthly'
  }, [subscription])

  const discounted = useMemo(() => {
    if (!amount) return false
    return discountAmount > 0
  }, [discountAmount])

  const isUnitPriced = useMemo(() => {
    if (!currentTier) return false
    if (!currentTier.unitAmount) return false
    if (currentTier.unitAmount > 0) return true
    return false
  }, [currentTier])

  const canAddChildren = useMemo(() => {
    if (!subscription) return false
    return (
      status !== 'Cancelled' &&
      tiered &&
      !isUnitPriced &&
      maxIncludedChildren < maxNbChildren &&
      // Chilren can't be changed during trials granted because of plan switch
      subscription.trial_end < (Date.now() + 7 * 24 * 60 * 60 * 1000) / 1000
    )
  }, [
    subscription,
    status,
    maxIncludedChildren,
    tiered,
    maxNbChildren,
    isUnitPriced,
  ])

  if (!subscription) return null

  return {
    tiered,
    maxNbChildren,
    maxIncludedChildren,
    canAddChildren,
    getAmountWithSubscriptionDiscount: applyDiscount,
    isUnitPriced,
    subscription: {
      expired,
      discountAmount,
      stripeBalance,
      interval,
      expiresAt,
      type,
      status,
      renewalDate,
      renewalCost,
      noDiscountPrice,
      discounted,
      lastPaymentDate,
      autoRenewCancelled,
      currency: subscription?.plan?.currency,
      planId: subscription?.plan?.id,
    },
  }
}

export default useStripeSusbcription
