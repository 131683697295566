import { useStripe } from "@stripe/react-stripe-js"
import { Source, StripeError } from "@stripe/stripe-js"
import React, { ReactElement, useState } from "react"
import styled from "styled-components"
import config from '../config'

const IFrameWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 100000;
  visibility: ${(props: { visible: boolean }) =>
    props.visible ? "visible" : "hidden"};

  > iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const use3DSecure = currency => {
  const stripe = useStripe()
  const [source, setSource] = useState<Source | undefined>()
  const [error, setError] = useState<StripeError | undefined>()
  const [displayed, setDisplayed] = useState(false)

  const getAmount = () => {
    if (currency === "inr") {
      return 3000
    }
    return 100
  }

  const element = <IFrameWrapper id="threeDSsecure" visible={displayed} />

  const onComplete = async event => {
    // The event is sent from /3dsecure
    if (!stripe) return
    const { detail } = event
    const { source: sourceId, clientSecret } = detail
    const { source, error } = await stripe.retrieveSource({
      id: sourceId,
      client_secret: clientSecret,
    })
    setDisplayed(false)
    window.removeEventListener("3DS-authentication-complete", onComplete)
    if (error) {
      setError(error)
      return
    }
    setSource(source)
  }

  const handle = async (card: any) => {
    if (!stripe) return false
    const { source, error } = await stripe.createSource({
      type: "three_d_secure",
      amount: getAmount(),
      currency,
      // @ts-ignore
      three_d_secure: {
        card: card.id,
      },
      redirect: {
        return_url: `${config.url}/3dsecure`,
      },
    })
    if (error) {
      throw error
    }
    setDisplayed(true)
    const iframe = document.createElement("iframe")
    if (!source || !source.redirect) return false
    iframe.src = source.redirect.url
    iframe.width = "500px"
    iframe.height = "600px"
    const element = document.querySelector("#threeDSsecure") as HTMLElement
    element.appendChild(iframe)
    window.addEventListener("3DS-authentication-complete", onComplete, false)
    return true
  }

  const returned: [
    element: ReactElement,
    handle: (source: any) => void,
    source: undefined | Source,
    error: undefined | StripeError
  ] = [element, handle, source, error]

  return returned
}

export default use3DSecure
