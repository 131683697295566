import { useApolloClient } from '@apollo/client'
import { decodeJwt } from 'jose'
import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { useCurrentUserState } from 'state/CurrentUserState'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { Source } from '@stripe/stripe-js'
import use3DSecure from 'hooks/use3DSecure'
import GET_PLAN from './graphql/getStripePlan.graphql'
import UPDATE_PLAN from './graphql/updateStripePlan.graphql'

const useUpdateSubscriptionState = (initialState) => {
  const client = useApolloClient()
  const [paymentRequest, setPaymentRequest] = useState()
  const [applePay, setApplePay] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [finalSource, setFinalSource] = useState<Source | undefined>()
  const { planId } = decodeJwt(initialState.token)
  const [plan, setPlan] = useState<any>(null)
  const [success, setSuccess] = useState(false)
  const { currentUser } = useCurrentUserState()
  const stripe = useStripe()
  const elements = useElements()
  const [element, threeDHandler, threeDSource, threeDError] = use3DSecure(plan?.currency)

  const fetchPlan = async () => {
    const { data } = await client.query({
      query: GET_PLAN,
      variables: {
        id: planId
      }
    })
    setPlan(data.plan)
  }

  useEffect(() => {
    fetchPlan()
  }, [planId])

  const submit = async (source) => {
    const { data } = await client.mutate({
      mutation: UPDATE_PLAN,
      variables: {
        token: initialState.token,
        stripeToken: source.id
      }
    })
    if (data) { setSuccess(true) }
    setSubmitting(false)
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!elements || !stripe) return
    if (!currentUser) return
    const cardNumber = elements.getElement("card")
    console.log(cardNumber)
    if (!cardNumber) return
    if (stripe) {
      setSubmitting(true)
      const { source, error } = await stripe.createSource(cardNumber, {
        type: 'card',
        owner: {
          name: currentUser.name,
          email: currentUser.email,
        },
      })
      if (error) {
        throw error
      }
      if (
        source &&
        source.card &&
        source.card.three_d_secure &&
        ['required'].indexOf(source.card.three_d_secure) >= 0
      ) {
        await threeDHandler(source)
      } else {
        setFinalSource(source)
      }
    }
  }

  useEffect(() => {
    if (threeDSource && threeDSource.status === "chargeable") {
      setFinalSource(threeDSource)
    } else if (threeDSource && threeDSource.status === "failed") {
      console.log("3DSecure Authentication Failed.")
    }
  }, [threeDSource])

  useEffect(() => {
    if (threeDError) {
      console.log('3DSecure Authentication Error')
    }
  }, [threeDError])

  useEffect(() => {
    if (finalSource) {
      submit(finalSource)
    }
  }, [finalSource])
  
  return {
    paymentRequest, setPaymentRequest,
    applePay, setApplePay,
    plan,
    handleSubmit,
    currentUser,
    submitting,
    success,
    element
  }
}

const UpdateSubscriptionState = createContainer(useUpdateSubscriptionState)

export default UpdateSubscriptionState